import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/Hero'
import { getImage, getSrc } from 'gatsby-plugin-image'

const BlogPostPage = ({ data }) => {
  const post = data.mdx
  const image = getImage(post.frontmatter.image)
  const imageSource = getSrc(post.frontmatter.image)
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        imageSource={imageSource}
        imageAlt={post.frontmatter.imageAlt}
      />
      {image ? (
        <Hero
          image={image}
          title={post.frontmatter.title}
          alt={
            post.frontmatter.imageAlt
              ? post.frontmatter.imageAlt
              : post.frontmatter.description
          }
          description={post.frontmatter.date}
        />
      ) : (
        <section id="banner">
          <div id="banner" className="inner">
            <header>
              <h2 className="hs h2">{post.frontmatter.title}</h2>
            </header>
            <p className="p">{post.frontmatter.date}</p>
          </div>
        </section>
      )}
      <article className="wrapper container style4 post">
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostById($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            gatsbyImageData(aspectRatio: 2.33)
          }
        }
      }
      body
    }
  }
`

export default BlogPostPage
